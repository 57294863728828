import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Proxy for the Websocket WQHD Videostream",
  "path": "/Frequently_Asked_Question/Websocket_Stream_Proxy/",
  "dateChanged": "2022-12-09",
  "author": "Mike Polinowski",
  "excerpt": "I have a 9008 and 9408 in operation. With the 9008, I have always been able to use an Apache proxy to assign an arbitrary domain to the MJPG stream. Is it generally no longer possible to use the stream via proxy?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Proxy for the Websocket WQHD Videostream' dateChanged='2022-12-09' author='Mike Polinowski' tag='INSTAR IP Camera' description='I have a 9008 and 9408 in operation. With the 9008, I have always been able to use an Apache proxy to assign an arbitrary domain to the MJPG stream. Is it generally no longer possible to use the stream via proxy?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Websocket_Stream_Proxy/' locationFR='/fr/Frequently_Asked_Question/Websocket_Stream_Proxy/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "proxy-for-the-websocket-wqhd-videostream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#proxy-for-the-websocket-wqhd-videostream",
        "aria-label": "proxy for the websocket wqhd videostream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Proxy for the Websocket WQHD Videostream`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I have a 9008 and 9408 in operation. With the 9008, I have always been able to use an Apache proxy to assign an arbitrary domain to the MJPG stream. Is it generally no longer possible to use the stream via proxy?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: You can use the following NGINX proxy configuration to forward and expose your cameras video stream securely to the internet. Start by `}<a parentName="p" {...{
        "href": "https://github.com/mpolinowski/docker_ws_video_proxy/archive/refs/heads/master.zip"
      }}>{`downloading`}</a>{` or cloning the repository:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` clone https://github.com/mpolinowski/docker_ws_video_proxy.git`}</code></pre></div>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#nginx-configuration"
        }}>{`NGINX Configuration`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#tls-certificates"
            }}>{`TLS Certificates`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#nginx-main-configuration"
            }}>{`NGINX Main Configuration`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#server-configuration"
            }}>{`Server Configuration`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#run-the-mjpeg-proxy"
            }}>{`Run the MJPEG Proxy`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#secure-mjpeg-proxy"
        }}>{`Secure MJPEG Proxy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#websocket-proxy"
        }}>{`Websocket Proxy`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "nginx-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nginx-configuration",
        "aria-label": "nginx configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NGINX Configuration`}</h2>
    <p>{`The important files inside this repository are:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`
├── certs
│   ├── nginx-selfsigned.crt
│   └── nginx-selfsigned.key
├── conf.d
│   ├── mjpeg-proxy.conf
│   ├── ws-proxy.conf
│   └── wss-proxy.conf
├── nginx.conf`}</code></pre></div>
    <h3 {...{
      "id": "tls-certificates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tls-certificates",
        "aria-label": "tls certificates permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`TLS Certificates`}</h3>
    <p><strong parentName="p">{`Note`}</strong>{` that the repository contains a self-signed TLS certificate and key. This can be used for testing - or simply generate a fresh one using the OpenSSL CLI tool:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` docker_ws_video_proxy/certs
openssl req -new -newkey rsa:4096 -x509 -sha256 -days `}<span parentName="code" {...{
            "className": "token number"
          }}>{`365`}</span>{` -nodes -out nginx-selfsigned.crt -keyout nginx-selfsigned.key`}</code></pre></div>
    <p>{`But `}<strong parentName="p">{`also note`}</strong>{` that Google Chrome is going to refuse a self-signed certificate for Websocket connections. You will have to replace them with CA signed certificates if you want to setup a secure connection.`}</p>
    <h3 {...{
      "id": "nginx-main-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#nginx-main-configuration",
        "aria-label": "nginx main configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NGINX Main Configuration`}</h3>
    <p>{`The main configuration file for the NGINX proxy is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nginx.conf`}</code>{`. Here you can choose what kind of proxy you want to setup - comment out all configuration files here that you `}<strong parentName="p">{`do not want`}</strong>{` to use:`}</p>
    <p><em parentName="p">{`docker`}{`_`}{`ws`}{`_`}{`video`}{`_`}{`proxy/nginx.conf`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# MJPEG proxy`}</span>{`
include /etc/nginx/conf.d/mjpeg-proxy.conf`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Secure MJPEG proxy`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# include /etc/nginx/conf.d/mjpeg-secure-proxy.conf;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Websocket proxy`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# include /etc/nginx/conf.d/ws-proxy.conf;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Secure websocket proxy`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# include /etc/nginx/conf.d/wss-proxy.conf;`}</span></code></pre></div>
    <h3 {...{
      "id": "server-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#server-configuration",
        "aria-label": "server configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Server Configuration`}</h3>
    <p>{`Next are the server configuration files themselves - for example the `}<strong parentName="p">{`MJPEG Proxy`}</strong>{` server:`}</p>
    <p><em parentName="p">{`docker`}{`_`}{`ws`}{`_`}{`video`}{`_`}{`proxy/conf.d/mjpeg-proxy.conf`}</em></p>
    <p>{`Here the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`upstream`}</code>{` host is always your IP cameras local IP address or domain name followed by the HTTP port - e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.120:80`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`upstream mjpeghost `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# IP and http port of your camera`}</span>{`
    server `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.120:80`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The `}<strong parentName="p">{`Server Block`}</strong>{` allows you to set the port on which you want NGINX to re-stream your camera's video, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8888`}</code>{`. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`server_name`}</code>{` is the IP address or domain name of the NGINX host system. In the example below I am re-streaming the video to localhost - this way it is only accessible on the NGINX host itself using the address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://127.0.0.1:8888`}</code>{`. And lastly there is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`proxy_pass`}</code>{` where you can adjust the `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/"
      }}>{`MJPEG URL`}</a>{` according to your needs. The example uses the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/13`}</code>{` stream - which is the smallest one. Change it to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/12`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/11`}</code>{` when needed. And, of course, you need to add your own username and password:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`server `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    listen `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8888`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# IP of your proxy server or localhost`}</span>{`
    server_name `}<span parentName="code" {...{
            "className": "token number"
          }}>{`127.0`}</span>{`.0.1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    location    / `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        proxy_pass http://mjpeghost/livestream/13?action`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`play`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`media`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`mjpeg`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        proxy_http_version `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        proxy_set_header Upgrade `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$http_upgrade`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        proxy_set_header Connection `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"upgrade"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        proxy_set_header Host `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$host`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$server_port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        proxy_set_header X-Forwarded-Proto `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$scheme`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        proxy_set_header X-Forwarded-For `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$remote_addr`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        proxy_set_header X-Forwarded-Port `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$server_port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        proxy_set_header X-Request-Start `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$msec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3 {...{
      "id": "run-the-mjpeg-proxy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#run-the-mjpeg-proxy",
        "aria-label": "run the mjpeg proxy permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Run the MJPEG Proxy`}</h3>
    <p>{`Use a volume mount into the NGINX container to over-write the default configuration. Make sure that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/path/to/docker_ws_video_proxy`}</code>{` point towards the repository you downloaded:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run --rm --network `}<span parentName="code" {...{
            "className": "token function"
          }}>{`host`}</span>{` -v /path/to/docker_ws_video_proxy:/etc/nginx --name proxy nginx:alpine`}</code></pre></div>
    <p>{`This will run the container attached to your terminal and destroy the container when you quit. The NGINX proxy is configured to send it's logs to STDOUT - so you will see all access attempts and error messages in your terminal. You can also run the container detached from your console:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -d --network `}<span parentName="code" {...{
            "className": "token function"
          }}>{`host`}</span>{` -v /path/to/docker_ws_video_proxy:/etc/nginx --name proxy nginx:alpine`}</code></pre></div>
    <p>{`To test if it is working open a browser on your host machine and visit `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://127.0.0.1:8888`}</code>{` - you should be able to see the MJPEG Stream of your camera - `}<strong parentName="p">{`without the need to log in`}</strong>{`. This is a much more secure way to publish your cameras video to a public web page - as the user that will access this stream will not be able to see the login credentials or send any CGI commands to your camera.`}</p>
    <h2 {...{
      "id": "secure-mjpeg-proxy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#secure-mjpeg-proxy",
        "aria-label": "secure mjpeg proxy permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Secure MJPEG Proxy`}</h2>
    <p>{`Now that we have the simple MJPEG streaming proxy up and running - let's make it a bit more complicated by adding TLS encryption. As mentioned above, the repository contains a self-signed TLS certificate and key. You replace those with your own - or even better - get a CA signed certificate, e.g. from Let's Encrypt.`}</p>
    <p>{`The changes we have to make to the NGINX configuration are fairly simple. Start by selecting the correct configuration file:`}</p>
    <p><em parentName="p">{`docker`}{`_`}{`ws`}{`_`}{`video`}{`_`}{`proxy/nginx.conf`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# MJPEG proxy`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# include /etc/nginx/conf.d/mjpeg-proxy.conf;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Secure MJPEG proxy`}</span>{`
include /etc/nginx/conf.d/mjpeg-secure-proxy.conf`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Websocket proxy`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# include /etc/nginx/conf.d/ws-proxy.conf;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Secure websocket proxy`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# include /etc/nginx/conf.d/wss-proxy.conf;`}</span></code></pre></div>
    <p>{`Now open the configuration file and add your information - just like in the simple MJPEG example above - but note that the `}<strong parentName="p">{`Server Block`}</strong>{` now holds the certificate and key file (`}<em parentName="p">{`which you should replace with your own`}</em>{` - but the included certificate will work for testing):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# your SSL configuration`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ssl_certificate /opt/letsencrypt/live/my.domain.com/fullchain.pem;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ssl_certificate_key /opt/letsencrypt/live/my.domain.com/privkey.pem;`}</span>{`
ssl_certificate /etc/nginx/certs/nginx-selfsigned.crt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Replace with the 2 lines above when using CA Cert`}</span>{`
ssl_certificate_key /etc/nginx/certs/nginx-selfsigned.key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Now stop the NGINX container - if it is still running - and re-start it:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run --rm --network `}<span parentName="code" {...{
            "className": "token function"
          }}>{`host`}</span>{` -v /path/to/docker_ws_video_proxy:/etc/nginx --name proxy nginx:alpine`}</code></pre></div>
    <p>{`Now you will have to use HTTPS to access your cameras live video `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`https://127.0.0.1:8888/`}</code>{`. When using a self-signed certificate your browser will warn you about that - just click to accept.`}</p>
    <h2 {...{
      "id": "websocket-proxy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#websocket-proxy",
        "aria-label": "websocket proxy permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Websocket Proxy`}</h2>
    <p>{`Both the basic and secure websocket proxy configuration are mostly identical to the examples above. So you should now have any issues editing the configuration files.`}</p>
    <p>{`But to access the websocket video stream you need a websocket client - like the one provided here:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/mpolinowski/html_ws_client"
        }}>{`html_ws_client`}</a></li>
    </ul>
    <p>{`You can embed this in your website and receive the stream from your NGINX proxy via it:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Website_Integration/WS_Stream_WQHD_Cameras/"
        }}>{`Website integration - WS stream for WQHD cameras`}</a></li>
    </ul>
    <p>{`As mentioned in the TLS section above - you browser will not accept a websocket (wss) connection using a self-signed certificate. You will need to use a tool like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`certbot`}</code>{` to generate a CA signed certificate and key for your proxy to be able to work with the secure websocket stream of your camera.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      